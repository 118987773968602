import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import store from '@/store'
import LocalizationService from '@/services/localization-service'

const InvoicesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/invoices`,
  rowsPath: 'invoices',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'fields.name',
      field: 'entity',
      formatFn: (val: any) => LocalizationService.getTranslateArEn(val),
    },
    {
      label: 'fields.type',
      field: 'type',
    },
    {
      label: 'invoice.cost',
      field: 'cost',
      formatFn: (val: any) => `${val} ${store.getters['auth/currency']}`,
    },
    {
      label: 'invoice.discount',
      field: 'discount',
      formatFn: (val: any) => `${val} ${store.getters['auth/currency']}`,
    },
    {
      label: 'invoice.paid',
      field: 'paid',
    },
    {
      label: 'invoice.paid-at',
      field: 'paid_at',
    },
    {
      label: 'fields.created-at',
      field: 'created_at',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
  ],
  filters: [
    {
      label: 'invoice.paid',
      query: 'paid',
      colLength: 3,
      type: 'select',
      multiple: false,
      options: [{ text: 'general.yes', value: 1 }, { text: 'general.no', value: 0 }],
      optionsLabel: { keyLabel: 'text', valueLabel: 'value', translate: true },
    },
  ],
}

export default InvoicesTableConfig
